import { template as template_d8b8a0826c974c1f9eac68ead36e7402 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_d8b8a0826c974c1f9eac68ead36e7402(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
