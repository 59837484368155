import { template as template_1ee2bf56ff3b4916a587073eb6cea9e7 } from "@ember/template-compiler";
const FKLabel = template_1ee2bf56ff3b4916a587073eb6cea9e7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
