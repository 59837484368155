import { template as template_e6837164b2c64036917c9987471b132c } from "@ember/template-compiler";
const FKControlMenuContainer = template_e6837164b2c64036917c9987471b132c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
