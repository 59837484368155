import { template as template_8d2f0164f5c74f4db58592f7d1d982b0 } from "@ember/template-compiler";
const FKText = template_8d2f0164f5c74f4db58592f7d1d982b0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
