import { template as template_83083715b61740129badd24314c3867f } from "@ember/template-compiler";
const WelcomeHeader = template_83083715b61740129badd24314c3867f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
